<template>
  <div class="home animate__animated animate__fadeIn pt-4">
<!--    <img
      :src="require('@/assets/banners/address-updates.png')"
      class="w-100 mb-4"
      style="display: none"
      @load="
        (e) => {
          e.target.style.display = 'block';
          hideSpinner('spinner-banner');
        }
      "
    />

    <div id="spinner-banner" class="text-center">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>-->

    <div class="container-addresses-manual mt-4" id="inputAdressesManual">
      <!-- Previous Postcode -->
      <div class="mb-4">
        <h5 class="fs-la">Previous Postcode</h5>
        <input
          class="mb-1 p-3 w-100 fs-md"
          :class="errors.moveFromPostCode ? 'error-input' : ''"
          type="text"
          placeholder="e.g WC2N 5DU"
          :value="moveFromPostCode"
          @input="(e) => updateValue('moveFromPostCode', e.target.value.toUpperCase(), true)"
          :disabled="referrer == 'FamilyUpdater'"
        />
        <p v-if="errors.moveFromPostCode" style="color: #c64a45">* Enter a valid postcode</p>
      </div>
      <!-- Previous Address -->
      <div class="mb-4">
        <h5 class="fs-la">Previous Address</h5>
        <input
          class="mb-2 p-3 w-100 fs-md"
          :class="errors.addresses ? 'error-input' : ''"
          type="text"
          placeholder="e.g 9 Diamonf Avenue..."
          :value="moveFromAddress"
          @input="(e) => updateValue('moveFromAddress', e.target.value)"
          :disabled="referrer == 'FamilyUpdater'"
        />
        <p v-if="errors.addresses" style="color: #c64a45">* This field is required</p>
      </div>
      <!-- New Postcode -->
      <div class="mb-4">
        <h5 class="fs-la">New Postcode</h5>
        <input
          class="mb-1 p-3 w-100 fs-md"
          :class="errors.moveToPostCode ? 'error-input' : ''"
          type="text"
          placeholder="e.g WC2N 5DU"
          :value="moveToPostCode"
          @input="(e) => updateValue('moveToPostCode', e.target.value.toUpperCase(), true)"
          :disabled="referrer == 'FamilyUpdater'"
        />
        <p v-if="errors.moveToPostCode" style="color: #c64a45">* Enter a valid postcode</p>
      </div>
      <!-- New Address -->
      <div class="mb-4">
        <h5 class="fs-la">New Address</h5>
        <input
          class="mb-2 p-3 w-100 fs-md"
          :class="errors.addresses ? 'error-input' : ''"
          type="text"
          placeholder="e.g 33 Rosemary Road"
          :value="moveToAddress"
          @input="(e) => updateValue('moveToAddress', e.target.value)"
          :disabled="referrer == 'AddressUpdater' || referrer == 'FamilyUpdater'"
        />
        <p v-if="errors.addresses" style="color: #c64a45">* This field is required</p>
      </div>
    </div>

    <div v-if="!addressManually" class="mb-4">
      <!-- <h5 class="fs-la">About your move</h5> -->
      <!-- Enter your previous postcode -->
      <h5 class="fs-la">
        I'm moving from <img :src="`${urlBucket}/form/assets/icons/pin.svg`" class="pin" />
      </h5>
      <div class="d-flex align-items-center search-1 mb-4" v-if="hasPreviousAddress">
        <!-- <div class="container-pins container-pin1">
          <img :src="`${urlBucket}/form/assets/icons/pin.svg`" class="pin1" />
        </div> -->
        <vSelect
          :filterable="false"
          :value="moveFromAddress"
          class="w-100 fs-md"
          :options="options"
          :clearable="false"
          :placeholder="`Enter your previous postcode`"
          :multiple="false"
          :disabled="referrer == 'FamilyUpdater'"
          @search="(search, loading) => onSearch(search.toUpperCase(), loading, 'moveFromPostCode')"
          @input="
            (e) => {
              updateValue('moveFromAddress', e.label);
              setCompleteAddress('completeMoveFromAddress', 'moveFromPostCode', e);
            }
          "
          ><!--:dropdown-should-open="dropdownShouldOpen" -->
          <template slot="selected-option" slot-scope="option">
            <!-- {{ option.label.length >= 47 ? option.label.slice(0, 47) + "..." : option.label }} -->
            {{ formatOptionSelected(option) }}
          </template>
        </vSelect>
      </div>
      <!-- Enter your new postcode -->
      <h5 class="fs-la">
        I'm moving to <img :src="require('@/assets/bluepin.png')" class="pin" />
      </h5>
      <div class="d-flex align-items-center search-1 mb-4">
        <!-- <div class="container-pins container-pin2">
          <img :src="require('@/assets/bluepin.png')" class="pin2" />
        </div> -->
        <vSelect
          :filterable="false"
          :value="moveToAddress"
          class="w-100 fs-md"
          :clearable="false"
          :options="options2"
          :placeholder="`Enter your new postcode`"
          :multiple="false"
          :disabled="referrer == 'FamilyUpdater'"
          @search="(search, loading) => onSearch(search.toUpperCase(), loading, 'moveToPostCode')"
          @input="
            (e) => {
              updateValue('moveToAddress', e.label);
              setCompleteAddress('completeMoveToAddress', 'moveToPostCode', e);
            }
          "
        >
          <template slot="selected-option" slot-scope="option">
            <!-- {{ option.label.length >= 47 ? option.label.slice(0, 47) + "..." : option.label }} -->
            {{ formatOptionSelected(option) }}
          </template>
        </vSelect>
      </div>
      <p v-if="errors.addresses" style="color: #c64a45">* This field is required</p>
      <!-- <div class="row mt-2">
        <div class="col d-block text-end">
          <label class="switch mb-2">
            <input
              type="checkbox"
              @change="checkClick('hasPreviousAddress')"
              :checked="hasPreviousAddress"
            />
            <span class="slider round"></span>
          </label>

          <p style="font-size: 9px">I have a previous address</p>
        </div>
      </div> -->
    </div>

    <!-- Enter your address manually -->
    <div class="row mb-1">
      <div class="col d-block text-end">
        <label class="switch mb-2">
          <input
            type="checkbox"
            @change="(payload) => checkClick('addressManually')"
            :checked="addressManually"
          />
          <span class="slider round"></span>
        </label>

        <p class="mb-0 fs-xs" style="margin-top: 3px">Enter your address manually</p>
      </div>
    </div>
    <!-- Move In Date !iKnowMyMoveInDate ||-->
    <div class="mb-4" v-if="showInputMoveInDate">
      <h5 class="fs-la">I'm moving in on</h5>
      <!-- <Datepicker
        :value="moveInDate"
        :highlighted="{ dates: [new Date(Date.now())] }"
        @selected="(e) => selectedChange('moveInDate', e)"
        id="datepicker-1"
        class="fs-md mb-3"
      /> -->
      <DateMultiSelect
        :dateModel="moveInDate"
        @formattedDate="(value) => updateValue('moveInDate', value)"
        @invalidDate="(value) => onInvalidDate('invalidMoveInDate', value)"
      />
      <p class="mt-2 mb-0 fs-md" v-if="errors.moveInDate" style="color: #c64a45">
        * This field is required
      </p>
      <div class="row mt-2 mb-3">
        <div class="col d-block text-end">
          <label class="switch mb-2">
            <input type="checkbox" @change="checkClickDate()" :checked="iKnowMyMoveInDate" />
            <span class="slider round"></span>
          </label>

          <p class="fs-xs" v-if="iKnowMyMoveInDate">I know my move in date</p>
          <p class="fs-xs" v-if="!iKnowMyMoveInDate">I don't know my move-in date yet</p>
        </div>
      </div>
      <div class="d-flex align-items-center mt-3">
        <p-check
          style="font-size: 22px"
          name="check"
          class="pretty p-icon p-curve p-thick p-jelly"
          color="primary"
          @change="toggleCheck"
          :value="moveOutFlag"
        >
          <div class="state">
            <i style="margin-left: -67%" class="icon mdi mdi-check"></i>
          </div>
        </p-check>
        <p class="mb-0 fs-md">Different move out date?</p>
      </div>
    </div>

    <!-- Move Out Date -->
    <div v-if="moveOutFlag" class="mb-3">
      <h5 class="fs-la">I'm moving out on</h5>
      <!-- <Datepicker
        :value="moveOutDate"
        :highlighted="{ dates: [new Date(Date.now())] }"
        @selected="(e) => updateValue('moveOutDate', e)"
        id="datepicker-2"
        class="fs-md"
      /> -->
      <DateMultiSelect
        :dateModel="moveOutDate"
        @formattedDate="(value) => updateValue('moveOutDate', value)"
        @invalidDate="(value) => onInvalidDate('invalidMoveOutDate', value)"
      />
      <p class="mt-2 mb-0 fs-md" v-if="errors.moveOutDate" style="color: #c64a45">
        * This field is required
      </p>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { isValid } from "postcode";
import pcToWater from "@/data/postCodeToWaterSupplier";
import { navigateTo } from "../router/index";
import DateMultiSelect from "../components/DateMultiSelect";
import vSelect from "vue-select";
import { scrollToBottom } from "../utils/scrollToTop.js";
import { isMobile } from "../utils/isMobile";
import Datepicker from "vuejs-datepicker";
// @ is an alias to /src

export default {
  name: "Home2",
  components: {
    vSelect,Datepicker, DateMultiSelect
  },
  data() {
    return {
      options: [],
      options2: [],
      invalidMoveInDate: false,
      invalidMoveOutDate: false,
      isMobile,
      statuses: ["Moving home in the future","Moving home today","Already in my new home"],
      displayConfirmEmail: false,
      errors: {
        firstName: false,
        email: false,
        confirmEmail: false,
        addresses: false,
        moveFromPostCode: false,
        moveToPostCode: false,
        moveInDate: false,
        moveOutDate: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    onInvalidDate(itemName, val) {
      this[itemName] = val;
    },
    selectedChange(itemName, value) {
      // console.log("selectedChange", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    toggleCheck(e) {
      this.$store.commit("formStore/setField", [`moveOutFlag`, e]);
    },
    regexEmail(email) {
      //Email
      const regexMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      return regexMail.test(email);
    },
    validateEmail() {
      if (this.email == "" || !this.regexEmail(this.email)) {
        this.errors.email = true;
      } else {
        this.errors.email = false;
      }
    },
    validateInputs() {
      this.validateEmail();
      this.errors.firstName = this.firstName == "" ? true : false;

      //Confirm Email
      if (this.email !== this.confirmEmail || !this.regexEmail(this.confirmEmail)) {
        this.errors.confirmEmail = true;
      } else {
        this.errors.confirmEmail = false;
      }

      //Postcodes
      if (!isValid(this.moveToPostCode.toUpperCase())) {
        this.errors.moveToPostCode = true;
      } else {
        this.errors.moveToPostCode = false;
      }

      if (!isValid(this.moveFromPostCode.toUpperCase())) {
        this.errors.moveFromPostCode = true;
      } else {
        this.errors.moveFromPostCode = false;
      }

      //Addresses
      if (this.moveToAddress == "" || (this.hasPreviousAddress && this.moveFromAddress == "")) {
        this.errors.addresses = true;
      } else {
        this.errors.addresses = false;
      }
      this.errors.moveInDate =
        this.showInputMoveInDate && this.iKnowMyMoveInDate && !this.invalidMoveInDate
          ? true
          : false;

      this.errors.moveOutDate =
        this.showInputMoveInDate && !this.invalidMoveOutDate
          ? this.moveOutFlag
            ? true
            : false
          : false;
    },
    checkClickDate() {
      this.$store.commit("formStore/setField", [`iKnowMyMoveInDate`, !this.iKnowMyMoveInDate]);
    },
    checkClick(itemName) {
      this.$store.commit("formStore/setField", [`addressManually`, !this[`${itemName}`]]);
      // console.log(this.addressManually);

      //Input manuals in Home
      if (!this[`${itemName}`]) {
        document.getElementById("inputAdressesManual").style.maxHeight = "0";
      }

      if (this[`${itemName}`]) {
        // const height = document.querySelector(".container-addresses-manual")?.scrollHeight;
        document.getElementById("inputAdressesManual").style.maxHeight = `100vh`;

        setTimeout(() => {
          scrollToBottom();
        }, 400);
      }
    },
    onSearch(search, loading, postcodeType) {
      const formatPostcode = (postcode) => {
        postcode = postcode.split("");
        if (postcode.length == 5) {
          postcode.splice(2, 0, ".");
        } else if (postcode.length == 6) {
          postcode.splice(3, 0, ".");
        } else if (postcode.length == 7) {
          postcode.splice(4, 0, ".");
        }

        return postcode.join("").replace(".", " ");
      };

      if (search.trim().search(" ") < 0) {
        search = formatPostcode(search.trim());
      }

      // console.log(search);

      if (search.length > 4 && isValid(search.toUpperCase().trim())) {
        loading(true);

        fetch(`${this.baseURL}/addresses/postcodeLookup?stream=${search}`, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((res) => {

            const postcode = search.trim();
            this.$store.commit("formStore/setField", [postcodeType, postcode]);

            let array = res.addresses.map((item) => {
              item.postcode = postcode;
              item.label = item.formatted_address.join(" ");

              //latitude and longitude
              item.latitude = res["latitude"];
              item.longitude = res["longitude"];

              return item;
            });

            if (postcodeType == "moveFromPostCode") this.options = array;

            if (postcodeType == "moveToPostCode") this.options2 = array;

            scrollToBottom();

            loading(false);
          })
          .catch((ev) => {
            console.error(ev);
            loading(false);
          });
      }
    },
    setCompleteAddress(addressName, postcodeType, data) {
      // console.log(data)
      this.$store.commit("formStore/setField", [addressName, data]);
      this.$store.commit("formStore/setField", [postcodeType, data.postcode.trim()]);

      // fetch(`${this.baseURL}/addresses/getFullComplete?stream=${id}`, {
      //   method: "GET",
      //   mode: "cors",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // }).then((res) => {
      //   res.json().then((json) => {
      //     this.$store.commit("formStore/setField", [addressName, json]);
      //     this.$store.commit("formStore/setField", [postcodeType, json.postcode]);
      //   });
      // });
    },
    updateValue(itemName, value, inputPostcodes) {
      // console.log("updateValue", value);
      if (inputPostcodes) {
        value = value.toUpperCase();
      }

      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    formatOptionSelected(option) {
      return option.label.length >= 47 && window.innerWidth <= 475
        ? option.label.slice(0, 47) + "..."
        : option.label;
    },
    dropdownShouldOpen(VueSelect) {
      return VueSelect.search.length > 5 && VueSelect.open;
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "About your move"]);

    this.displayConfirmEmail = this.regexEmail(this.email);

    if (this.addressManually) {
      document.getElementById("inputAdressesManual").style.maxHeight = `100vh`;
    }

    const validatePage = () => {
      this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        // this.$router.push("/services");
        navigateTo("/services");
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        /* (this.referrer == "adv2" || this.referrer == "amber") && */ this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "email",
      "firstName",
      "status",
      "confirmEmail",
      "referrer",
      "baseURL",
      "urlBucket",
      "moveToPostCode",
      "moveFromPostCode",
      "moveToAddress",
      "moveFromAddress",
      "hasPreviousAddress",
      "addressManually",
      "completeMoveToAddress",
      "completeMoveFromAddress",
      "moveInDate",
      "moveOutDate",
      "moveOutFlag",
      "iKnowMyMoveInDate",
      "showInputMoveInDate",
    ]),
  },
  watch: {
    hasPreviousAddress(newVal, oldVal) {
      let className = "hide-address-from-input";

      if (newVal == false) {
        document.getElementsByClassName("container-pin2")[0].classList.add(className);
        document.querySelector(".search-2 .vs__dropdown-toggle").classList.add(className);
      }

      if (newVal == true) {
        document.getElementsByClassName("container-pin2")[0].classList.remove(className);
        document.querySelector(".search-2 .vs__dropdown-toggle").classList.remove(className);
      }
    },
    completeMoveToAddress: function (obj, oldVal) {
      // console.log("completeMoveToAddress", obj);
      //newCouncil - newWater
      let outcodeNew = obj.postcode.split(" ")[0];

      this.$store.commit("formStore/setField", ["newWater", pcToWater[outcodeNew]]);

      this.$store.commit("formStore/setField", [
        "newCouncil",
        obj.district ? obj.district : obj.county,
      ]);
    },
    completeMoveFromAddress: function (obj, oldVal) {
      // console.log("completeMoveFromAddress", obj);
      //oldWater - oldCouncil
      this.$store.commit("formStore/setField", ["oldWater", pcToWater[obj.postcode.split(" ")[0]]]);
      this.$store.commit("formStore/setField", [
        "oldCouncil",
        obj.district ? obj.district : obj.county,
      ]);
    },
    email(newVal, oldVal) {
      this.displayConfirmEmail = this.regexEmail(newVal)
    }
  },
};
</script>
<style lang="scss">
.home {
  .select-status {
    .vs__selected {
      color: black;
    }
    .vs__dropdown-toggle {
      padding: 0.8rem !important;
      border-radius: 0.375rem;
      color: black;
    }

    .vs__dropdown-menu {
      color: black !important;
    }
  }
  input:disabled {
    background-color: white;
  }

  .v-select {
    .vs__search,
    .vs__search:focus {
      font-size: inherit;
      padding-left: 16px;
      /*padding: 0px !important;*/
    }

    .vs__actions {
      padding-right: 12px;
    }

    color: black;
    font-weight: 600;

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }

    .vs__selected {
      margin: 4px 0px 0;
      padding-left: 16px;
      font-weight: 400;
    }

    input::placeholder {
      color: #828282;
    }
  }

  .container-pins {
    padding: 7.5px;
  }

  .container-pin1 {
    background-color: white;
    border-radius: 0.4rem 0 0 0;

    .pin1 {
      height: 40px;
    }
  }

  .container-pin2 {
    background-color: #32343e;
    border-radius: 0 0 0 0.4rem;

    .pin2 {
      height: 40px;
    }
  }

  .hide-address-from-input {
    border-radius: 0.4rem 0 0 0.4rem;
  }

  .search-1 {
    .vs__dropdown-toggle {
      border-radius: 0.4rem;
      /*border-radius: 0 0.4rem 0 0;*/
      z-index: 9999999 !important;
      padding-left: 0px !important;
    }

    .vs__open-indicator {
      fill: black !important;
    }
  }

  .search-2 {
    .v-select {
      color: black;
      font-weight: 600;

      .vs__dropdown-toggle {
        border-radius: 0 0 0.4rem 0;
        background-color: #32343e;
        padding-left: 0px !important;
      }

      .vs__search {
        color: white;
      }

      .vs__selected {
        color: white;
      }

      .vs__open-indicator {
        fill: white !important;
      }
    }
  }
}

.pin {
  width: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}

#inputAdressesManual {
  max-height: 0;
  margin: 0 auto;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}
</style>
